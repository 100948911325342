import request from '@/utils/ucenter_request.js'
import bbsrequest from '@/utils/bbsrequest.js'

// 头部 user/logon/signIn
export function signIn(params) {
  return request({
    url: '/user/logon/signIn',
    method: 'get',
    params
  })
}
// APP登录二维码 /user/logon/signInByQrcode
export function signInByQrcode(params) {
  return request({
    url: '/user/logon/signInByQrcode',
    method: 'get',
    params
  })
}
// 发送验证码 /user/logon/sendMobileCode
export function sendMobileCode(params) {
  return request({
    url: '/user/logon/sendMobileCode',
    method: 'get',
    params
  })
}
// 获取社群弹窗 /user/Logon/getBbsQrCode
export function getBbsQrCode(params) {
  return request({
    url: '/user/Logon/getBbsQrCode',
    method: 'get',
    params
  })
}
// 监控二维码登录 /user/logon/loginForApp
export function loginForApp(params) {
  return request({
    url: '/user/logon/loginForApp',
    method: 'get',
    params
  })
}
// 退出登录 /user/logon/signOut
export function signOut(params) {
  return request({
    url: '/user/logon/signOut',
    method: 'get',
    params
  })
}
// 登录重定向
export function loginurl(params) {
  return bbsrequest({
    url: '/index/index/loginurl',
    method: 'get',
    params
  })
}
// 登录送积分
export function loginScore(params) {
  return request({
    url: '/user/logon/loginScore',
    method: 'get',
    params
  })
}

// 微信扫码关注获取二维码
export function getwxqrcode(params) {
  return request({
    url: 'wechat/wechat/getwxqrcode',
    method: 'get',
    params
  })
}
// 微信扫码关注绑定账号
export function weixinBind(params) {
  return request({
    url: 'ucenter/login/weixinBind',
    method: 'get',
    params
  })
}

// 获取换绑二维码
export function getChangeWxBindQrcode(params) {
  return request({
    url: '/wechat/wechat/getChangeWxBindQrcode',
    method: 'get',
    params
  })
}

// 发送验证码(新)
export function sendMobileCodeNew(params) {
  return request({
    url: '/ucenter/login/sendMobileCode',
    method: 'post',
    params
  })
}
// 用户名密码登录(新)
export function loginByUserName(params) {
  return request({
    url: '/ucenter/login/loginByUserName',
    method: 'post',
    params
  })
}
// 手机验证码登录(新)
export function loginByPhone(params) {
  return request({
    url: '/ucenter/login/loginByPhone',
    method: 'post',
    params
  })
}
// 手机验证码绑定(新)
export function bindMobile(params) {
  return request({
    url: '/ucenter/user/bindMobile',
    method: 'post',
    params
  })
}
// 获取我的信息
export function getLoginUserInfo(params) {
  return request({
    url: 'personal/myinfo/getUserinfo',
    params: params,
    method: 'get'
  })
}
// 微信换绑接口
export function changeAccountWeixinBindByUuidToken(params) {
  return request({
    url: '/ucenter/login/changeAccountWeixinBindByUuidToken',
    method: 'post',
    params
  })
}

// 删除帐号微信绑定数据
export function removeBindData(params) {
  return request({
    url: '/ucenter/user/removeBindData',
    method: 'post',
    params
  })
}

// 设置兴趣点id
export function setCategory(params) {
  return request({
    url: '/ucenter/user/setCategory',
    params: params,
    method: 'get'
  })
}
// 获取登录方式配置
export function getSetting(params) {
  return request({
    url: '/ucenter/login/getSetting',
    method: 'get',
    params
  })
}
// 获取机构名称
export function getAgencyInfo(params) {
  return bbsrequest({
    url: '/index/index/getAgencyInfo',
    method: 'get',
    params
  })
}

// 获取添加好友小程序链接
export function getMiniUrlLink(params) {
  return request({
    url: '/ucenter/miniprogram/getMiniUrlLink',
    method: 'get',
    params
  })
}
