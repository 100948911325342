<!-- 我的筑龙页面 -->
<!-- <router-link :to="{path:'/useragreement'}">《用户信息协议》</router-link> -->
<template>

  <div ref="zhulong" class="main">
    <div v-if="!isZkb">
      <div class="top_box">
        <div class="t_msg">
          <div class="t_i">
            <div class="t_i_left" :style="is_avatar==='0'?'':'position:relative;top:20px;'">
              <div v-if="honour_title">
                <div class="honer_level" @click="scoreClick">{{ honour_title }}</div>
              </div>
              <div class="dh_gl_zl">
                <div class="dh" @click="goTask">任务</div>
                <div class="v_l"></div>
                <div class="dh" @click="exchangeAction">兑换</div>
                <div class="v_l"></div>
                <div class="gl" @click="gonglueAction">攻略</div>
              </div>
            </div>
            <div v-if="!isZkb" class="remind">
              <div class="remind_top">
                <span>防挂机弹窗：</span>
                <el-switch v-model="is_popup" active-color="#13ce66" @change="switchChange()"></el-switch>
              </div>
              <div class="remind_bottom">
                <span>注：关闭后，所有的视频跟直播将不再弹出防挂机弹窗</span>
              </div>
            </div>
          </div>
          <!-- v-if="is_avatar==='0'" -->
          <div class="need_ava" :style="is_avatar==='0'?'visibility:visible;':'visibility:hidden;'">
            修改头像立即获得<b>500</b>{{ zlb_name }}
            <el-upload class="icon_upload" action="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar" :show-file-list="false" :on-success="handleAvatarSuccess">
              <el-button size="small" type="primary">立即修改</el-button>
            </el-upload>
            <img src="https://newoss.zhulong.com/forum/202104/15/10/1618454289273968.png" alt="">
          </div>
          <ul class="t_col">
            <li>
              <div class="text1"><b>{{ coins }}</b></div>
              <div class="text2">{{ zlb_name }}</div>
              <div class="text3 text_p" @click="coinRecord">{{ zlb_name }}</div>
            </li>
            <li>
              <div class="text1"><b>{{ score }}</b></div>
              <div class="text2">荣誉分</div>
              <div class="text3 text_p" @click="scoreClick">{{ honour_title }}</div>
            </li>
            <li>
              <div class="text1"><b v-if="Math.floor(study_hour/60)">{{ Math.floor(study_hour/60) }}</b><span v-if="Math.floor(study_hour/60)" class="txt_time">小时</span>
                <b v-if="study_hour%60">{{ study_hour%60 }}</b><span v-if="study_hour%60" class="txt_time">分钟</span>
                <b v-if="study_hour===0">{{ study_hour }}</b><span v-if="study_hour===0" class="txt_time">分钟</span>
              </div>
              <div class="text2">本周学习时长</div>
              <div class="text3">
                <hr class="text_l">
              </div>
            </li>
            <li>
              <div class="text1"><b>{{ down_num }}</b></div>
              <div class="text2">我的下载</div>
              <div class="text3 text_p" @click="downRecord">下载记录</div>
            </li>
            <li>
              <div class="text1"><b>{{ threads_num }}</b></div>
              <div class="text2">我的主题</div>
              <div class="text3 text_p" @click="lookThreads">查看主题</div>
            </li>
          </ul>
        </div>
        <div class="te_quan_box">
          <p class="title">我的特权</p>
          <div class="items_box">
            <div v-if="add_img_url && add_img_url.length > 0" class="one_item">
              <img class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/7/173607jaydnxll1fzh7kvo.png" alt="">
              <div class="mid_box">
                <p class="title">我的专属客服</p>
                <p class="detail">请添加您的专属客服</p>
              </div>
              <div class="right_menu">
                <span class="red_btn" @click="addTeacher">去添加</span>
              </div>
            </div>
            <div class="one_item">
              <img v-if="give_vip_num > -1" class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/59/175759j6vkhzfkackbcc7l.png" alt="">
              <img v-else class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/10/175810869plzpzkhfechrr.png" alt="">
              <div class="mid_box">
                <p class="title">会员赠送特权
                  <span v-if="give_vip_num > -1" class="red_tip">{{ give_vip_num }}张待使用</span>
                  <span v-else class="red_tip">开通VIP会员解锁</span>
                </p>
                <p class="detail">赠送好友【筑龙VIP会员(1个月)】使用权</p>
              </div>
              <div class="right_menu">
                <span v-if="give_vip_num > 0 && (send_friend_code && send_friend_code.length > 0)" class="red_btn" @click="sendFriend">送朋友</span>
                <span v-else class="gray_btn">送朋友</span>
              </div>
            </div>
            <div class="one_item" :class="give_vip_num > -1?'one_last_item':'one_last_item1'" @click="showVipLessons">
              <img v-if="give_vip_num > -1" class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/29/175829ma2szsrjaqfgvqwm.png" alt="">
              <img v-else class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/21/175821cbufnql10i94gqis.png" alt="">
              <div class="mid_box">
                <p class="title">精品课赠送特权
                  <span v-if="give_vip_num > -1" class="red_tip">{{ give_lesson_num }}张待使用</span>
                  <span v-else class="red_tip">开通VIP会员解锁</span>
                </p>
                <p class="detail">手机打开VIP精品课简介页，点击右上角「请朋友看」</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gray_l"></div>
      <!--  -->
      <div v-if="send_evip_popup" class="send_evip_box">
        <div class="send_evip_bg">
          <div class="close" @click="closeSendPopup">
            <img src="https://newoss.zhulong.com/forum/202412/03/50/164950p0exrrwv8mb1ayvm.png" alt="">
          </div>
          <img class="send_evip_img" src="https://newoss.zhulong.com/forum/202412/09/11/1733713231172560.jpg" alt="">
        </div>
      </div>
      <!-- <div v-if="showUpgradeTip" class="new_box">
        <div class="code_box">
          <img :src="showUpgradeTipQrcode" alt="">
        </div>
        <div class="tips_box">
          <p class="tip_p" v-html="showUpgradeTipMsg"></p>
          <p class="tip_p">👈🏻 扫码添加您的<span class="blod">「专属客服」</span>，领取更多会员权益！</p>
        </div>
        <div class="close" @click="closeCodeFunc">
          <img src="https://newoss.zhulong.com/forum/202412/03/50/164950p0exrrwv8mb1ayvm.png" alt="">
        </div>
      </div> -->
      <div class="list_nav">
        <!-- 我收藏的专题 -->
        <!-- v-show="my_course.length > 0" -->
        <div v-show="topicList.length > 0 && !isZkb" class="my_topic">
          <div class="item_title">
            <p>我收藏的专题</p>
            <div class="lesson_more" @click="lookMoreTopic">
              <span>全部收藏</span>
              <img src="@/assets/image/more_arrow.png" alt="">
            </div>
          </div>
          <div class="main_box">
            <div v-for="(item,index ) in topicList" :key="index" class="topic_item">
              <a :href="item.url" target="_blank"><img class="thumb" :src="item.banner" alt=""></a>
              <div class="collect">
                {{ getNum(item.collect_num) }}
                <span class="text">收藏</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 免费讲座 -->
        <div v-show="fress_list.length > 0 && !isZkb" class="free_box">
          <div class="item_title">
            <p>免费讲座</p>
            <div class="tit_r" @click="lookMoreFree">
              加入筑龙社群，每年畅享500场专家讲座
            </div>
          </div>
          <div class="free_list">
            <ul>
              <li v-for="(item) in fress_list" :key="item.index" @click="vip_lessonDetail(item)">
                <p>{{ item.lesson_name }}</p>
                <span>{{ item.time }}</span>
              </li>
            </ul>
          </div>
          <div class="item_more" @click="lookMoreFree">
            <span>查看更多</span>
            <img src="@/assets/image/more_arrow.png" alt="">
          </div>
        </div>
        <!-- 我的课程 -->
        <div v-show="my_course.length > 0" class="my_course">
          <div class="item_title">
            <p>我的课程</p>
            <div class="lesson_more" @click="lookMoreLesson">
              <span>查看全部</span>
              <img src="@/assets/image/more_arrow.png" alt="">
            </div>
          </div>
          <div class="course_list">
            <ul>
              <li v-for="(lesson) in my_course" :key="lesson.index" @click="goLessonDetail(lesson)">
                <img :src="lesson.thumb" alt="" class="course_img">
                <span class="left_top">已购</span>
                <p class="course_name">{{ lesson.lesson_name }}</p>
                <div class="course_bot">
                  <span class="c_bot_left">
                    <img src="https://newoss.zhulong.com/cdn/edu/img/edu_index/pnum.png" alt="">
                    {{ lesson.student_count }}人学习
                  </span>
                  <span class="c_bot_right">
                    <img src="https://newoss.zhulong.com/cdn/edu/img/edu_index/flower.png" alt="">
                    {{ lesson.comment_rate | rateFilter }}好评率
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 我的社区 -->
        <div v-show="my_bbs.length > 0 && !isZkb" class="my_bbs">
          <div class="item_title">
            <p>我的社区</p>
          </div>
          <div class="bbs_list">
            <ul>
              <li v-for="(bbs) in my_bbs" :key="bbs.index" @click="groupDetail(bbs)">
                <p>{{ bbs.old_group_name }}</p>
                <span>({{ bbs.thread_num }})</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- 精品帖子 -->
        <div v-show="jp_bbs_jp.length > 0 || jp_bbs_hot.length > 0 && !isZkb" class="jp_thread">
          <div class="item_title">
            <p>精品帖子</p>
          </div>
          <div class="jptz_box">
            <div class="jptz_box_left">
              <ul v-show="jp_bbs_jp.length > 0">
                <li v-for="(item,index) in jp_bbs_jp" :key="index" @click="goThreadDetail(item)">
                  <p v-if="index === 0" class="jp_tit_black">
                    <span>精华帖</span>
                    {{ item.title }}
                  </p>
                  <p v-else class="jp_tit_normal"><span class="reddot">•</span>{{ item.title }}</p>
                </li>
              </ul>
            </div>
            <div class="jptz_box_right">
              <ul v-show="jp_bbs_hot.length > 0">
                <li v-for="(item,index) in jp_bbs_hot" :key="index" @click="goThreadDetail(item)">
                  <p v-if="index === 0" class="jp_tit_black">
                    <span>热门帖</span>
                    {{ item.title }}
                  </p>
                  <p v-else class="jp_tit_normal"><span class="reddot">•</span>{{ item.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 推荐关注 -->
        <div v-show="commend.length > 0 && !isZkb" class="commond_att">
          <div class="item_title">
            <p>推荐关注</p>
          </div>
          <div class="comm_box">
            <ul>
              <li v-for="(item) in commend" :key="item.index">
                <div class="item_list">
                  <img onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" :src="item.avatar" alt="" @click="gouser(item.uid)">
                  <p class="name" @click="gouser(item.uid)">{{ item.username }}</p>
                  <p class="spcial">{{ item.from }}</p>
                  <div v-if="item.is_follow===1" class="yiguanzhu" @click="cancelFollowUser(item)">已关注</div>
                  <div v-else class="guanzhu" @click="followsUser(item)">关注</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="zkb">
      <div class="zkb_head">
        <div class="div_content">
          <img onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" :src="user_info.avatar">
          <span class="sp_name">{{ user_info.username }}</span>
        </div>
        <p class="line" />
      </div>
      <div class="zkb_content">
        <div class="div_1">
          <p class="p_num">{{ user_info.score ? user_info.score : 0 }}</p>
          <p class="p_tag">{{ zlb_name }}</p>
          <!-- <p class="p_tag">积分记录</p> -->
          <div class="dh_gl" style="margin-right: 56px;">
            <div class="dh" @click="exchangeAction">兑换</div>
            <!-- <div class="v_l"></div>
            <div class="gl" @click="gonglueAction">攻略</div>-->
          </div>
        </div>
        <!-- <div class="div_1">
          <p class="p_num">{{ user_info.keep ? user_info.keep :0 }}</p>
          <p class="p_tag">坚持学习天数</p>
        </div> -->
        <div class="div_1">
          <p class="p_num">{{ user_info.hours ? user_info.hours : 0 }}分钟</p>
          <p class="p_tag">视频学习时长</p>
        </div>
        <div class="div_1">
          <p class="p_num">{{ user_info.exercise_sum ? user_info.exercise_sum : 0 }}</p>
          <p class="p_tag">做题数量</p>
        </div>
        <div class="div_1">
        </div>
      </div>
    </div>

    <!-- 添加专属客服弹窗 -->
    <el-dialog title="" :visible.sync="showAddPop" class="add_pop_dialog" width="300px">
      <div class="pop_body">
        <p class="tip1">恭喜您升级为</p>
        <p class="tip2">【{{ grouptitle }}】</p>
        <p class="tip3">您可以添加筑龙学社教学总监的微信，有问题/有建议您可以直接反馈。</p>
        <img class="qrcode" :src="add_img_url" alt="">
        <div class="tips">微信扫码分享</div>
      </div>
    </el-dialog>

    <!-- 送朋友会员弹窗 -->
    <el-dialog title="" :visible.sync="showFriendPop" class="add_pop_dialog" width="300px">
      <div class="pop_body">
        <img :src="send_friend_code" alt="" class="qr_page_code">
        <div class="tips">微信扫码分享</div>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import tool from '@/utils/tools.js'
import { Message } from 'element-ui'
import { getMyZhulong, getMyScore, follows, cancelfollow, setUserPopup, getUserPopup, getLoginUserInfo, getVipCardCollection, getCanSend } from '@/api/center'
import { updateIcon, getRow } from '@/api/mine'
import { getMiniUrlLink } from '@/api/login'
import { mapMutations, mapActions } from 'vuex'
import { followList } from '@/api/regInfo.js'
import Cookies from 'js-cookie'
export default {
  filters: {
    formatDate(time) {
      const t = time * 1000
      return tool.formatDate(t, 'yyyy-MM-dd hh:mm')
    },
    rateFilter(rate) {
      const r = Number(rate)
      const s = r + '%'
      return s
    }
  },
  data() {
    return {
      isZkb: false,
      params: {},
      userinfo: {},
      coins: 0,
      score: 0,
      study_hour: 0,
      down_num: 0,
      threads_num: 0,
      // 是否修改过头像
      is_avatar: '1',
      // 兑换礼物链接
      gift_url: '',
      // 攻略链接
      strategy: '',
      // 免费讲座加入社群 、 查看更多 链接
      vip_url: '',
      fress_list: [],
      my_course: [],
      my_bbs: [],
      jp_bbs_jp: [],
      jp_bbs_hot: [],
      commend: [],
      // 荣誉分等级
      honour_title: '',
      zlb_name: '',
      is_popup: true,
      user_info: {},
      agency_id: '',
      // 我收藏的专题
      topicList: [],
      // 升级提示
      showUpgradeTip: false,
      showUpgradeTipMsg: '',
      showUpgradeTipQrcode: '',
      // 赠送会员弹窗
      send_evip_popup: false,
      // 专属客服弹窗
      showAddPop: false,
      grouptitle: '', // 等级名
      add_img_url: '', // 专属客服二维码链接
      // 送朋友会员弹窗
      showFriendPop: false,
      send_friend_code: '', // 邀请好友的邀请码
      give_lesson_num: 0, // 可以送课程数量
      give_vip_num: -1 // 可以送vip的数量，-1未开通或过期
    }
  },
  created() {
    this.agency_id = Cookies.get('agency_id')
    const origin = window.location.origin
    if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    this.getMyZhulongData()
    if (!this.isZkb) {
      this.getCommentFollows()
      this.getVipCardCollectionFun()
      this.getCanShowUpgradeTip()
      this.getOpenShareVipUrl()
      this.getIsCanSend()
    } else {
      this.getLoginUserInfo()
    }
  },
  methods: {
    ...mapMutations(['storageToken']),
    ...mapActions({
      'cacheUsername': 'userInfo/storageTheUsername',
      'cacheUid': 'userInfo/storageTheUid',
      'cacheAvatar': 'userInfo/storageTheAvatar',
      'cacheVipLevel': 'userInfo/storageTheVipLevel'
    }),
    getIsCanSend() {
      getCanSend().then((res) => {
        if (res.errNo === 0) {
          if (res.result) {
            if (this.checkCanShowUpgradeTip('showSendEvip')) {
              this.send_evip_popup = true
            }
          } else {
            this.send_evip_popup = false
          }
        }
      })
    },
    closeSendPopup() {
      this.send_evip_popup = false
      const curTime = new Date().getTime()
      localStorage.setItem('showSendEvip', curTime)
    },
    goTask() {
      this.$router.push({
        path: '/taskcenter'
      })
    },
    // 我收藏的专题
    getVipCardCollectionFun() {
      var info = {
        limit: 4
      }
      getVipCardCollection(info).then((res) => {
        if (res.errNo == 0) {
          this.topicList = res.result.list
        }
      })
    },
    // 我的筑龙
    getMyZhulongData() {
      getMyZhulong(this.params).then((res) => {
        // this.user_info = res.result
        const userinfo = res.result.user
        const freeList = !res.result.VIP_lesson ? [] : res.result.VIP_lesson
        const myLesson = !res.result.my_lesson ? [] : res.result.my_lesson
        const myBbs = !res.result.group ? [] : res.result.group
        const essence = !res.result.new_thread.essence ? [] : res.result.new_thread.essence
        const bbs_hot = !res.result.new_thread.hot ? [] : res.result.new_thread.hot

        this.fress_list = freeList
        this.my_course = myLesson
        this.my_bbs = myBbs
        this.userinfo = userinfo
        this.jp_bbs_jp = essence
        this.jp_bbs_hot = bbs_hot

        // 数
        const zlb = Number(userinfo.score)
        if (zlb > 0) {
          this.coins = zlb
        }
        this.score = userinfo.honour_score
        this.study_hour = res.result.hours
        this.down_num = res.result.download
        this.threads_num = res.result.thread
        this.is_avatar = userinfo.is_avatar
        // 荣誉分等级
        const honour_text = userinfo.honour_title
        if (honour_text && honour_text != null && honour_text.length > 0) {
          this.honour_title = honour_text
        } else {
          this.honour_title = ''
        }
        // 链接地址
        this.gift_url = res.result.gift_url
        this.strategy = res.result.strategy
        this.vip_url = res.result.vip_url

        // 开关状态
        this.is_popup = res.result.is_popup === 1

        // // 存储信息
        // const uid = userinfo.uid
        // const name = userinfo.username
        // const avatar = userinfo.avatar
        // const vip_level = userinfo.vip_level
        // this.cacheUsername({ username: name })
        // this.cacheUid({ uid: uid })
        // this.cacheAvatar({ avatar: avatar })
        // this.cacheVipLevel({ vip_level: vip_level })
      })
      getRow().then(res => {
        this.zlb_name = res.result.name
        // if (this.isZkb) {
        //   this.zlb_name = '注考币'
        // }
      })
      if (!this.isZkb) {
        this.getMyScoreData()
      }
    },
    // 我的注考帮
    getLoginUserInfo() {
      getLoginUserInfo().then(res => {
        if (res.errNo == 0) {
          this.user_info = res.result
          console.log(res, 'res')
        }
      })
    },
    // 获取推荐数据
    getCommentFollows() {
      const post = {
        limit: 6,
        page: 1
      }
      followList(post).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const list = result.list ? result.list : []
          if (list.length > 6) {
            this.commend = list.slice(0, 6)
          } else {
            this.commend = list
          }
        }
      })
    },
    getMyScoreData() {
      getMyScore(this.params).then(res => {
        const zlb = Number(res.result.score)
        if (zlb > 0) {
          this.coins = zlb
        }
      })
    },
    switchChange() {
      const popup = this.is_popup ? 1 : 0
      const info = {
        is_popup: popup
      }
      setUserPopup(info).then(res => {
        if (res.errNo === 0) {
          this.getSwitchStatus()
        }
      })
    },
    getSwitchStatus() {
      getUserPopup().then(res => {
        if (res.errNo === 0) {
          this.is_popup = res.result.is_popup === 1
        }
      })
    },
    // 更多专题
    lookMoreTopic() {
      this.$router.push('/collecttopic')
    },
    coinRecord() {
      this.$router.push('/coin')
    },
    scoreClick() {
      this.$router.push('/credit')
    },
    downRecord() {
      this.$router.push('/download')
    },
    lookThreads() {
      this.$router.push('/post')
    },
    exchangeAction() {
      if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
        window.open(window.location.origin + '/ucenter/gift', '_blank')
      } else {
        window.open(this.gift_url, '_blank')
        console.log('兑换')
      }
    },
    gonglueAction() {
      window.open(this.strategy, '_blank')
      console.log('攻略')
    },
    vip_lessonDetail(item) {
      // 免费讲座item点击去详情
      window.open(item.url, '_blank')
    },
    lookMoreFree() {
      window.open(this.vip_url, '_blank')
      console.log('更多免费讲座')
    },
    lookMoreLesson() {
      this.$router.push('/buylesson')
    },
    goLessonDetail(item) {
      // 课程详情
      window.open(item.url, '_blank')
    },
    // 修改头像
    handleAvatarSuccess(res, file) {
      const param = { filename: res.url }
      updateIcon(param).then((res) => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.getMyScoreData()
          const avaurl = res.result.result
          this.cacheAvatar({ avatar: avaurl })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    groupDetail(bbs) {
      // 去小组详情
      window.open(bbs.detail_url, '_blank')
    },
    goThreadDetail(item) {
      // 帖子详情
      window.open(item.url, '_blank')
    },
    cancelFollowUser(item) {
      // 取消关注用户
      const param = { uid: item.uid }
      cancelfollow(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.getMyZhulongData()
          item.is_follow = 0
        } else {
          const msg = res.msg
          Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    followsUser(item) {
      // 关注用户
      const param = { uid: item.uid }
      follows(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.getMyZhulongData()
          item.is_follow = 1
        } else {
          const msg = res.msg
          Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 人员信息
    gouser(uid) {
      console.log(uid, 'uid')
      // this.$router.push({
      //   path: '/top',
      //   query: {
      //     uid: uid
      //   }
      // })
      window.location.href = `https://www.zhulong.com/ucenter/archive?u=${uid}`
    },
    getNum(num) {
      if (num < 1000) {
        return num
      } else if (num >= 1000 && num < 10000) {
        return parseInt((num / 1000 * 10)) / 10 + 'k'
      } else if (num >= 10000) {
        return parseInt((num / 10000 * 10)) / 10 + 'w'
      }
    },
    // 获取展示升级会员提示
    getCanShowUpgradeTip() {
      getLoginUserInfo({}).then(res => {
        const result = res.result || {}
        const img_url = result.img_url || ''
        const give_lesson_num = result.give_lesson_num || 0
        const give_vip_num = result.give_vip_num + ''
        this.grouptitle = result.grouptitle || ''
        this.add_img_url = img_url
        this.give_lesson_num = parseInt(give_lesson_num)
        if (give_vip_num && give_vip_num.length > 0) {
          this.give_vip_num = parseInt(give_vip_num)
        } else {
          this.give_vip_num = -1
        }
      })
    },
    // 检测是否能显示升级提示
    checkCanShowUpgradeTip(storageKey = 'showUpgradeTipTime') {
      if (localStorage.getItem(storageKey) && localStorage.getItem(storageKey)) {
        // 展示过，判断规则：一天一次
        const lastTime = localStorage.getItem(storageKey)
        const curDayZeroTime = this.getStartOfDay().getTime()
        if (lastTime < curDayZeroTime) {
          return true
        } else {
          return false
        }
      } else {
        // 展示
        return true
      }
    },
    // 升级会员提示点击关闭
    closeCodeFunc() {
      this.showUpgradeTip = false
      // 关闭之后当前天 不再显示
      const curTime = new Date().getTime()
      localStorage.setItem('showUpgradeTipTime', curTime)
    },
    // 获取当天零点
    getStartOfDay() {
      const now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    // 获取分享vip特权的，打开小程序对应分享页面链接
    getOpenShareVipUrl() {
      // share_uid=8588314&share_name=惜月老师
      const query = {
        'path': '/pages/sendVIP/sendVIP',
        'env_version': 'release',
        'query': '',
        'auth_app_id': 'wx828b87bfa158b387',
        'type': 1
      }
      getMiniUrlLink(query).then(res => {
        if (res.errNo == 0) {
          const code_url = res.result || ''
          if (code_url && code_url.length > 0) {
            this.send_friend_code = 'https://f.zhulong.com/qrcode.php?data=' + code_url
          } else {
            this.send_friend_code = ''
          }
        }
      })
    },
    // 添加我的专属客服
    addTeacher() {
      this.showAddPop = true
    },
    // 送朋友会员弹窗
    sendFriend() {
      // 获取跳转小程序的地址，然后转成二维码
      this.showFriendPop = true
    },
    // 进入vip课程列表页面
    showVipLessons() {
      if (this.give_vip_num > -1) {
        const url = `${window.location.origin}/vip/`
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.send_evip_box{
  .send_evip_bg{
    width: 100%;
    height: 300px;
    position: relative;
    background:url('https://newoss.zhulong.com/forum/202412/12/11/1733975698837727.png') no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
    .send_evip_img{
      position: absolute;
      top: 81px;
      right: 83px;
      width: 110px;

    }
    .close{
      width:30px;
      height:30px;
      position:absolute;
      top:10px;
      right:10px;
      cursor: pointer;
      img{
        width: 100%;
      }
    }
  }
}
.list_nav{
  overflow: hidden;
}
.main {
  background-color: #f2f2f2;
  // width: 100%;
  // min-height: 800px;
  font-family: MicrosoftYaHei;
}
.need_ava {
      width: 300px;
      text-align: left;
      height: 34px;
      margin-top: 0px;
      line-height: 34px;
      margin-left: 60px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ee2e2e;

      .icon_upload {
        display: inline-block;
        /deep/.el-button {
          background-color: #fff;
          border: none;
          border-bottom: 1px solid #ee2e2e;
          border-radius: 0;
          color: #ee2e2e;
          font-size: 14px;
          padding: 0;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      span {
        line-height: 34px;
        border-bottom: 1px solid #ee2e2e;
        cursor: pointer;
      }
    }
.honer_level{
  background: #d2b56b;
  color: #fff;
  font-size: 16px;
  padding: 4px 20px;
  border-radius: 20px;
  margin-top: 22px;
  margin-left: 63px;
  cursor: pointer;
}
.dh_gl ,.dh_gl_zl{
  width: 114px;
  height: 34px;
  border-radius: 17px;
  margin-top: 22px;
  margin-left: 63px;
  border: solid 1px #4184e8;
  line-height: 34px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #548eea;
  .dh {
    display: inline-block;
    cursor: pointer;
  }
  .v_l {
    display: inline-block;
    width: 1px;
    height: 12px;
    background-color: #dadada;
    margin: 0 10px;
  }
  .gl {
    display: inline-block;
    cursor: pointer;
  }
}
.dh_gl_zl{
  width: 150px;
  border: solid 1px #d2b56b;
  color: #d2b56b;
}
.top_box {
  width: 100%;
  padding: 20px 0;
  background-color: #fff;
  .t_msg {
    .t_col {
      display: flex;
      flex-direction: row;
      width: 100%;
      li {
        display: inline-block;
        width: 20%;
        height: 120px;
        div {
          width: 100%;
          height: 40px;
          line-height: 40px;
        }
      }
      .text1 {
        font-family: MicrosoftYaHei-Bold;
        font-size: 20px;
        color: #333333;
        .txt_time {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #333333;
        }
      }
      .text2 {
        font-family: "PingFangSC-Regular";
        font-size: 14px;
        color: #333333;
      }
      .text3 {
        font-family: "PingFangSC-Regular";
        font-size: 12px;
        font-weight: bold;
        color: #999999;
        text-align: center;
        vertical-align: middle;
      }
      .text_l {
        height: 2px;
        width: 15px;
        position: relative;
        margin-top: 20px;
        background-color: #999999;
        left: 45%;
        border: none;
      }
      .text_p {
        cursor: pointer;
      }
    }
  }
  .t_i {
    padding-top: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // float: left;
    .t_i_left {
      display: flex;
    }
    .need_ava {
      width: 300px;
      text-align: left;
      height: 34px;
      margin-top: 22px;
      line-height: 34px;
      margin-left: 40px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ee2e2e;

      .icon_upload {
        display: inline-block;
        /deep/.el-button {
          background-color: #fff;
          border: none;
          border-bottom: 1px solid #ee2e2e;
          border-radius: 0;
          color: #ee2e2e;
          font-size: 14px;
          padding: 0;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      span {
        line-height: 34px;
        border-bottom: 1px solid #ee2e2e;
        cursor: pointer;
      }
    }
    .remind {
      width: 340px;
      height: 100%;
      margin-top: 22px;
      margin-right: 60px;
      text-align: right;
    }
    .remind_top {
      span {
        font-size: 15px;
        font-weight: bold;
        margin-right: 10px;
      }
    }
    .remind_bottom {
      margin-top: 8px;
      span {
        color: #999;
      }
    }
  }
  .te_quan_box {
    text-align: left;
    margin-top: 15px;
    padding: 10px 20px 0;
    .title {
      color: rgba(16,16,16,1);
      font-size: 18px;
      font-weight: 600;
    }
    .items_box {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .one_item {
        margin-right: 15px;
        font-family: PingFangSC-Regular;
        padding: 6px 6px;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #F9F9F9;
        .left_icon {
          margin-right: 10px;
          width: 25px;
          display: block;
        }
        .mid_box {
          flex: 1;
          .title {
            color: #101010;
            font-weight: 600;
            font-size: 14px;
          }
          .detail {
            margin-top: 5px;
            color: #9a9a9a;
            font-size: 12px;
          }
          .red_tip {
            margin-left: 5px;
            font-size: 12px;
            color: #ee2e2e;
          }
        }
        .right_menu {
          margin-left: 25px;
          width: 50px;
          .red_btn {
            display: inline-block;
            margin-left: 0;
            width: 50px;
            padding: 2px 0px;
            border-radius: 5px;
            background-color: #ee2e2e;
            color: #fff;
            font-size: 12px;
            text-align: center;
            cursor: pointer;
          }
          .gray_btn {
            display: inline-block;
            margin-left: 0;
            width: 50px;
            padding: 2px 0px;
            border-radius: 5px;
            background-color: #bbbbbb;
            color: #fff;
            font-size: 12px;
            text-align: center;
          }
        }
      }
      .one_last_item {
        cursor: pointer;
        margin-right: 0px;
      }
      .one_last_item1 {
        margin-right: 0px;
      }
    }
  }
}
.gray_l {
  width: 100%;
  height: 15px;
  background-color: #f2f2f2;
}
.free_box {
  background-color: #fff;
  width: 100%;
  padding: 30px 28px;
  float: left; /***/
}
.free_list {
  width: 100%;
  // float: left;
  margin-top: 25px;
  text-align: left;
  display: inline-block;
  cursor: pointer;
  li {
    width: 435px;
    height: 30px;
    float: left;
    span {
      float: right;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      line-height: 30px;
      color: #adadad;
    }
    p {
      width: 320px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      line-height: 30px;
      color: #444444;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.free_list li:nth-of-type(2n) {
  margin-left: 39px;
}
.item_more {
  float: right;
  margin-top: 20px;
  height: 32px;
  width: 102px;
  cursor: pointer;
  border: 1px solid #adadad;
  border-radius: 30px;
  span {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    line-height: 30px;
    color: #adadad;
  }
  img {
    vertical-align: revert;
    margin-left: 7px;
  }
}
.lesson_more {
  float: right;
  margin-top: 5px;
  height: 21px;
  cursor: pointer;
  span {
    font-family: MicrosoftYaHei;
    font-size: 15px;
    line-height: 20px;
    color: #adadad;
  }
  img {
    margin-top: -5px;
    margin-left: 7px;
  }
}
.item_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  p {
    display: inline-block;
    font-size: 28px;
    font-weight: bold;
    height: 100%;
    line-height: 60px;
    color: #333333;
    font-family: MicrosoftYaHei-Bold;
    border-bottom: 4px solid #ee2e2e;
  }
  .tit_r {
    width: 300px;
    height: 30px;
    border-radius: 15px;
    border: solid 1px #e50f15;
    font-family: "PingFangSC-Regular";
    // font-weight: bold;
    font-size: 15px;
    line-height: 28px;
    color: #e50f15;
    cursor: pointer;
  }
}
.my_course {
  background-color: #fff;
  width: 100%;
  padding: 30px 28px 0px 28px;
  float: left;
}
.course_list {
  width: 100%;
  margin-top: 35px;
  text-align: left;
}
.course_list ul li {
  position: relative;
  display: inline-block;
  width: 210px;
  margin-right: 23px;
}
.course_list li:nth-of-type(4n) {
  margin-right: 0px;
}
.course_list .course_img {
  width: 210px;
  height: 160px;
  cursor: pointer;
}
.course_list .left_top {
  display: inline-block;
  width: 50px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-color: #ee2e2e;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  position: absolute;
  left: 0;
  border-radius: 0 0 5px 5px;
}
.course_list .course_name {
  width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 10px 0 10px 0;
  padding: 0 0 0 0;
}
.course_list .course_bot {
  display: flex;
  justify-content: space-between;
  color: #999;
  width: 210px;
  height: 20px;
  margin: auto;
  font-size: 12px;
  line-height: 20px;
  padding: 0 0 0 0;
  margin-bottom: 25px;
}
.course_list .course_bot .c_bot_left {
  display: inline-block;
  align-items: end;
  width: 110px;
  padding-right: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.course_list .course_bot .c_bot_left img {
  margin-top: -4px;
  margin-right: 1px;
}

.course_list .course_bot .c_bot_right {
  align-items: end;
  width: 100px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.course_list .course_bot .c_bot_right img {
  margin-top: -2px;
  margin-right: -2px;
}

.my_bbs {
  background-color: #fff;
  width: 100%;
  padding: 30px 28px;
  float: left;
}
.bbs_list {
  width: 100%;
  margin-top: 30px;
  text-align: left;
  display: inline-block;
  li {
    width: 20%;
    height: 30px;
    float: left;
    cursor: pointer;
    p {
      float: left;
      max-width: 120px;
      font-size: 14px;
      line-height: 28px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      width: 65px;
      font-size: 14px;
      line-height: 28px;
      color: #adadad;
    }
  }
}

.jp_thread {
  background-color: #fff;
  width: 100%;
  padding: 30px 28px;
  float: left;
}
.jptz_box {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 25px;
  .jptz_box_left {
    width: 50%;
    float: left;
    li {
      height: 35px;
      align-items: center;
    }
  }
  .jptz_box_right {
    width: 50%;
    float: left;
    li {
      height: 35px;
      align-items: center;
    }
  }
  .jp_tit_black {
    font-size: 14px;
    color: #333333;
    line-height: 35px;
    font-weight: bold;
    width: 445px;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    span {
      background-color: #ee2e2e;
      display: inline-block;
      width: 68px;
      height: 25px;
      color: #fff;
      font-size: 12px;
      font-weight: normal;
      line-height: 25px;
      text-align: center;
      border-radius: 5px;
      // margin-left: 2px;
      margin-right: 15px;
    }
  }
  .jp_tit_normal {
    // margin-left: 5px;
    font-size: 14px;
    line-height: 35px;
    font-weight: normal;
    width: 445px;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
  }
  .jp_tit_normal:hover {
    color: #ee2e2e;
  }
  .reddot {
    color: #ee2e2e;
    margin-right: 10px;
  }
}
.commond_att {
  background-color: #fff;
  width: 100%;
  padding: 30px 28px;
  float: left;
  .comm_box {
    background-color: #fff;
    width: 100%;
    margin-top: 35px;
    display: inline-block;
    height: 190px;
    li {
      display: inline-block;
      width: 131px;
      height: 182px;
      text-align: center;
      box-shadow: 0px 0px 5px #999;
      margin-top: 4px;
      margin-right: 20px;
      border-radius: 5px;
      .item_list {
        width: 100%;
        height: 100%;
        img {
          margin-top: 16px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          cursor: pointer;
        }
        .name {
          margin: 13px 0 0 0;
          height: 20px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          line-height: 20px;
          color: #444444;
          cursor: pointer;
        }
        .spcial {
          margin: 10px 0 18px 0;
          height: 15px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          line-height: 20px;
          color: #999999;
        }
        .guanzhu {
          width: 75px;
          height: 23px;
          background-color: #ee2e2e;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          margin: auto;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .yiguanzhu {
          width: 75px;
          height: 23px;
          background-color: #999999;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          margin: auto;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}
.zkb {
  background: white;
  .zkb_head {
    position: relative;
    height: 187px;
    .line {
      width: 100%;
      height: 1px;
      background: #eeeeee;
      position: absolute;
      bottom: 0;
    }
    .div_content {
      padding: 27px;
      margin-top: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      .sp_name {
        height: 20px;
        font-size: 14px;
        margin-left: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      img {
        width: 59px;
        height: 59px;
        border-radius: 50%;
      }
    }
  }

  .zkb_content {
    padding-top: 28px;
    display: flex;
    justify-content: space-between;
    .p_num {
      height: 20px;
      font-size: 20px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #333333;
      line-height: 30px;
    }
    .p_tag {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-top: 27px;
    }
    .div_1 {
      flex: 1;
      height: 200px;
    }
  }
}
.my_topic{
  background-color: #fff;
  width: 100%;
  padding: 30px 28px 0px 28px;
  .main_box{
      // padding: 28px;
      margin-top:30px;
      display: flex;
      flex-wrap: wrap;
      .topic_item{
        width: 443px;
        height: 189px;
        margin-right: 23px;
        margin-bottom: 30px;
        position: relative;
        .thumb{
          width: 100%;
          height: 100%;
        }
        .collect{
          position:absolute;
          bottom:0;
          right: 0;
          background: rgba(51,51,51,0.90);
          border-radius: 6px 0px 0px 0px;
          color:#fff;
          padding: 2px 10px;
          font-size: 14px;
          .text{
            margin-left: 4px;
          }
        }
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
}
.new_box {
  width: 100%;
  padding: 14px 0;
  border-radius: 16px;
  background: linear-gradient(180deg, rgba(255,154,154,0.79) 0%,rgba(255,178,178,0.44) 100%);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  .code_box {
    margin-left: 25px;
    width: 65px;
    height: 65px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .tips_box {
    flex: 1;
    margin-left: 25px;
    text-align: left;
    .tip_p {
      margin: 8px 0;
      line-height: 22px;
      color: rgba(16,16,16,1);
      font-family: PingFangSC-Regular, PingFang SC;
      /deep/.blod {
        font-weight: 800;
      }
    }
  }
  .close {
    margin-right: 16px;
    width: 25px;
    height: 25px;
    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.add_pop_dialog {
  /deep/.el-dialog{
    background: #fff !important;
    box-shadow: 0 0 0 0 !important;
  }
  .pop_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .qr_page_code {
      width: 250px;
      display: block;
    }
    .tip1 {
      color: #ee2e2e;
      font-size: 16px;
    }
    .tip2 {
      margin-top: 15px;
      color: #333;
      font-size: 14px;
      font-weight: 600;
    }
    .tip3 {
      margin: 15px 0;
      color: #333;
      font-size: 14px;
    }
    .tips {
      margin-top: 15px;
      color: #333;
      font-size: 14px;
    }
  }
}
</style>
