<style lang='scss' scoped>
    .honerScoreLog {
        margin: 0 auto;
        position: relative;
        width: 965px;
        height: 700px;
        background-color: #fff;
        padding: 0 20px 20px 20px;
    }

    .div_updatePwd li {
        position: relative;
        width: 100%;
        height: 46px;
        margin-top: 25px;
        text-align: left;
    }

    .span_color {
        display: inline-block;
        line-height: 46px;
        width: 110px;
        color: #999999;
        text-align: right;
        margin-right: 15px;
        font-size: 14px;
    }

    .span_tip_common {
        height: 46px;
        position: absolute;
        margin-left: 20px;
        font-size: 12px;
        width: 300px;
    }

    .span_tip_red {
        font-size: 14px;
        color: #ee2e2e;
        line-height: 46px;

    }

    .span_tip_grey {
        color: #999999;
    }

    .input {
        width: 302px;
        height: 46px;
        background-color: #ffffff;
        border: solid 1px #cfcfcf;
        padding-left: 25px;
        outline: none;
    }

    .div_updatePwd button {
        width: 90px;
        height: 30px;
        background-color: #ee2e2e;
        border-radius: 6px;
        margin-top: 57px;
        color: white;
        cursor: pointer;
        margin-left: 124px;
    }

    .btn_ok {
        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-right: 32px;
        margin-left: 28px;
    }

    .span_safe {
        width: 61px;
        height: 12px;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .div_safe {
        height: 21px;
        margin-left: 124px;
        margin-top: 22px;
    }

    .span_safe_common {
        display: inline-block;
        width: 36px;
        height: 6px;
        margin-bottom: 2px;
    }

    .span_safe_0 {
        background-color: #dfdfdf;
    }

    .span_safe_1 {
        background-color: #f14f4f;
    }

    .span_safe_2 {
        background-color: #00a0e9;
    }

    .span_safe_3 {
        background-color: lightgreen;
    }

    .div_tip {
        width: 908px;
        height: 168px;
        background-color: #FEF8F8;
        padding: 20px 25px 20px 25px;
        margin-top: 24px;
        border: 1px dashed #EBEEF5;

        li {
            color: #64676E;
            height: 32px;
            text-align: left;
            line-height: 20px;
        }

    }
    .myclass-title{
      width: 100%;
      display:flex;
      justify-content:space-between;
      align-items:center;
      .scoringGuide{
        font-size: 14px;
        color:#ee2e2e;
        border: 1px solid #ee2e2e;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        border-radius: 180px;
        cursor: pointer;
      }
    }
    .explain{
      font-weight: 600;
    }
    .gray999{
      color: #999;
    }

</style>
<template>
  <div class="honerScoreLog" style="height: 864px">
    <p class="myclass-title">
      <span class="title-text">荣誉分记录
        <span v-if="resultData.result.count>0">({{ resultData.result.count }})</span>
      </span>
      <span class="title-border"></span>
      <span class="scoringGuide" @click="toScoreGuide">
        得分攻略
      </span>
    </p>
    <el-table
      :data="tableData"
      style="width:100%"
      stripe
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        label="日期"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="isExpire(scope.row.expire_time)?'gray999':''" style="margin-left: 0">{{ scope.row.table_create_time }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column
        label="操作原因"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_operation_reson }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="数量"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="isExpire(scope.row.expire_time)?'gray999':''" style="margin-left: 0">{{ scope.row.table_number }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="荣誉分"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="isExpire(scope.row.expire_time)?'gray999':''" style="margin-left: 0">{{ scope.row.table_honor_score }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="说明"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            <span :class="isExpire(scope.row.expire_time)?'gray999':''" style="margin-left: 0">{{ scope.row.table_remarks }}</span>
            <div v-if="scope.row.expire_time">
              <span :class="isExpire(scope.row.expire_time)?'gray999':''">（有效期30天）</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="详情"
        width="154"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_detail }}</span>
        </template>
      </el-table-column> -->

    </el-table>
    <el-pagination
      background
      style="margin-top: 24px"
      layout="prev, pager, next"
      :page-size="10"
      :total="this.resultData.result.count"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <div class="div_tip">
      <ul>
        <li>
          <b>【温馨提示】</b>
        </li>
        <li>
          1：快速获得荣誉的途径：在论坛上传精品资料、购买筑龙商品（课程、社区会员、vip会员）、互动活跃。
        </li>
        <li>
          2：互动活跃获得的荣誉分有效期为30天。
        </li>
        <li class="explain">
          说明：荣誉分为负的默认显示为0
        </li>
      </ul>
    </div>

  </div>

</template>
<script>
import tool from '../../../utils/tools'
import { getHonorLog } from '../../../api/mine'

export default {
  data() {
    return {
      params: {
        page: '',
        limit: 10
      },
      resultData: {
        msg: '',
        result: {
          list: [],
          count: 0
        }
      },
      tableData: []
    }
  },
  created() {
    this.params.page = 1
    this.getHonorScore(this.params)
  },
  methods: {
    // 判断荣誉分是否过期的方法
    isExpire(expire_time) {
      if (expire_time) {
        const now = new Date().getTime() / 1000
        const expire = new Date(expire_time).getTime()
        // console.log('now', now, 'expire', expire)
        if (now > expire) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    toScoreGuide() {
      window.open('https://www.zhulong.com/bbs/d/32362219.html', '_blank')
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getHonorScore(this.params)
    },
    // 获取vip日志信息
    getHonorScore(params) {
      getHonorLog(params).then((res) => {
        this.resultData.msg = res.msg
        this.resultData.result = res.result
        this.tableData = []
        const result = res.result.list
        for (let i = 0; i < result.length; i++) {
          const obj = {
            table_create_time: '',
            table_operation_reson: '',
            table_number: '',
            table_honor_score: '',
            table_remarks: '',
            table_detail: '',
            expire_time: ''
          }
          obj.table_create_time = result[i].create_time
          // 1：帖子回复星级奖励，2：帖子热度奖励 其他：特殊操作
          if (parseInt(result[i].type) === 1) {
            obj.table_operation_reson = '帖子回复星级奖励'
          } else if (parseInt(result[i].type) === 2) {
            obj.table_operation_reson = '帖子热度奖励'
          } else {
            obj.table_operation_reson = '特殊操作'
          }
          obj.table_number = result[i].score
          obj.table_honor_score = result[i].sumscore
          obj.table_remarks = result[i].reason
          obj.table_detail = ''
          obj.expire_time = result[i].expire_time
          this.tableData.push(obj)
        }
      })
    },
    // 修改table header的背景色
    tableHeaderColor() {
      return 'color: #020202;font-weight:600;font-size:14px;text-align:center'
    }
  }
}
</script>

