<template>
  <div class="mine">
    <div ref="sideBar" class="tac">
      <el-row :class="isZkb?'':'el_row'">
        <el-col :span="12" class="left">
          <div ref="zkbUser" class="user">
            <!-- <el-upload class="icon_upload" action="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar" :show-file-list="false" :on-success="handleAvatarSuccess">
              <div class="head">
                <img onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" :src="avatar" alt>
                <div class="change_ava">修改头像</div>
              </div>
            </el-upload> -->
            <my-upload
              v-if="show_box"
              ref="myUpload"
              :model-value.sync="show_box"
              field="img"
              :no-square="true"
              img-format="jpg"
              :size="size"
              url="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar"
              @crop-upload-success="handleAvatarSuccess"
            ></my-upload>
            <div class="head" @click="toggleShow">
              <img :src="avatar" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
              <div class="change_ava">修改头像</div>
            </div>
            <div class="u_info">
              <span class="u_name">{{ username }}</span>
              <img v-if="!isZkb && vip_level===4" src="@/assets/image/mine_e_icon.png" alt>
              <img v-if="!isZkb && vip_level===2" src="@/assets/image/mine_vipicon.png" alt>
            </div>
            <p v-if="!isZkb" class="p_main" @click="showArchive">我的主页</p>
            <div v-if="hint_show" class="need_ava" @click="toggleShow">
              <span>更换用户头像，获得500{{ zlb_name }}</span>
              <i class="el-icon-close" @click.self="close_hint"></i>
            </div>
          </div>
          <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo" router unique-opened active-text-color="#ee2e2e" @open="handleOpen" @close="handleClose">
            <template v-for="item in menuList">
              <el-submenu v-if="item.children.length" :key="item.id" :index="transfromStr(item.id)">
                <template slot="title">
                  <div class="ttext">
                    <div>
                      <img :src="item.image" alt>
                    </div>
                    <p>{{ item.title }}</p>
                  </div>
                </template>
                <el-menu-item v-for="(items, key) in item.children" :key="items.id" :index="agency_id==2?items.url:items.url+'?lesson_id='+$route.query.lesson_id+'&lesson_name='+$route.query.lesson_name">
                  {{ items.title }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item v-else :key="item.id" :index="agency_id==2?item.url:item.url+'?lesson_id='+$route.query.lesson_id+'&lesson_name='+$route.query.lesson_name" class="item">
                <div class="ttext">
                  <div>
                    <img :src="item.image" alt>
                  </div>
                  <p>{{ item.title }}</p>
                </div>
              </el-menu-item>
            </template>
            <!-- 测试菜单 -->
            <!-- <el-menu-item index="/evaluating" class="item">
              <template slot="title">
                <div class="ttext">
                  <div>
                    <img src="@/assets/image/mine_myzhulong.png" alt>
                  </div>
                  <p>我的评测</p>
                </div>
              </template>
            </el-menu-item> -->
          </el-menu>
        </el-col>
        <transition name="fade-transform" mode="out-in">
          <div class="right">
            <router-view></router-view>
          </div>
        </transition>
      </el-row>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { getLoginUserInfo } from '@/api/center'
import { mapMutations, mapActions } from 'vuex'
import { updateIcon, getRow } from '@/api/mine'
import { getMenuApi } from '@/api/Zs'
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'

export default {
  name: 'Sidebar',
  inject: ['reload'],
  components: {
    'my-upload': myUpload
  },
  data() {
    return {
      isZkb: false, // 是否是注考帮
      agency_id: '', // 机构id
      uid: '',
      toPath: '',
      avatar: '',
      username: '',
      vip_level: '',
      zlb_name: '',
      menuList: '',
      show_box: false, // 剪切框显示和隐藏的flag
      size: 2.1,
      hint_show: false
    }
  },
  watch: {
    '$route.path'(to, from) {
      console.log('to is ' + to + ' from is ' + from)
      if (to.path !== from.path) {
        this.reload()
      }
    },
    '$store.state.userInfo.username'(newV) {
      this.username = newV
    },
    '$store.state.userInfo.avatar'(newV) {
      this.avatar = newV
    },
    '$store.state.userInfo.vip_level'(newV) {
      this.vip_level = newV
    }
  },
  created() {
    this.agency_id = Cookies.get('agency_id')
    if (this.$store.state.userInfo.uid !== '') {
      this.uid = this.$store.state.userInfo.uid
    }
    if (this.$store.state.userInfo.username !== '') {
      this.username = this.$store.state.userInfo.username
    }
    if (this.$store.state.userInfo.avatar !== '') {
      this.avatar = this.$store.state.userInfo.avatar
    }
    if (this.$store.state.userInfo.vip_level !== '') {
      this.vip_level = this.$store.state.userInfo.vip_level
    }
    // 没获取到就请求
    this.getUserInfo()
    // 获取左侧菜单
    this.getMenu()
  },
  mounted() {
    const origin = window.location.origin
    if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
      this.isZkb = true
      this.$refs.zkbUser.style = 'height: 155px'
    } else {
      this.isZkb = false
    }
    this.$refs.sideBar.style.minHeight = (document.body.clientHeight - 100) + 'px'
  },
  methods: {
    ...mapMutations(['storageToken']),
    ...mapActions({
      'cacheUsername': 'userInfo/storageTheUsername',
      'cacheUid': 'userInfo/storageTheUid',
      'cacheAvatar': 'userInfo/storageTheAvatar',
      'cacheVipLevel': 'userInfo/storageTheVipLevel'
    }),
    close_hint() {
      this.hint_show = false
    },
    // 上传组件头像
    toggleShow() {
      this.show_box = !this.show_box
      // this.$refs.myUpload.setStep(1)
    },
    // 数字转为字符串
    transfromStr(id) {
      return id + ''
    },
    // 获取左侧菜单
    async getMenu() {
      const { errNo, result } = await getMenuApi()
      if (!errNo) {
        const resultElement = result[0]
        if (resultElement) {
          resultElement.url = '/mine/'
        }
        if (this.isZkb) {
          const zkbresult = [
            {
              'id': 11,
              'title': '我的注考帮',
              'parent_id': 0,
              'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E7%AD%91%E9%BE%99.png',
              'sort': 0,
              'url': '/',
              'status': 1,
              'create_time': '2021-12-20 17:20:01',
              'update_time': '2021-12-20 17:20:04',
              'children': []
            },
            {
              'id': 13,
              'title': '我的课程',
              'parent_id': 0,
              'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E8%AF%BE%E7%A8%8B.png',
              'sort': 0,
              'url': '',
              'status': 1,
              'create_time': '2021-12-20 17:20:01',
              'update_time': '2021-12-20 17:20:04',
              'children': [
                {
                  'id': 14,
                  'title': '已购买课程',
                  'parent_id': 13,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E7%AD%91%E9%BE%99.png',
                  'sort': 0,
                  'url': '/buylesson',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 15,
                  'title': '未付款课程',
                  'parent_id': 13,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/waitorder',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 77,
                  'title': '过期课程',
                  'parent_id': 13,
                  'image': 'https://newoss.zhulong.com/forum/202305/08/10/1683511451467272.png',
                  'sort': 0,
                  'url': '/expirelesson',
                  'status': 1,
                  'create_time': '2023-05-08 12:00:00',
                  'update_time': '2023-05-08 12:00:00',
                  'children': []
                }
              ]
            },
            {
              'id': 12,
              'title': '我的快递',
              'parent_id': 0,
              'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
              'sort': 0,
              'url': '/express',
              'status': 1,
              'create_time': '2021-12-20 17:20:01',
              'update_time': '2021-12-20 17:20:04',
              'children': []
            },
            {
              'id': 35,
              'title': '我的足迹',
              'parent_id': 0,
              'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E8%B6%B3%E8%BF%B9.png',
              'sort': 0,
              'url': '',
              'status': 1,
              'create_time': '2021-12-20 17:20:01',
              'update_time': '2021-12-20 17:20:04',
              'children': [
                {
                  'id': 37,
                  'title': '浏览课程',
                  'parent_id': 35,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/browselesson',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                }
              ]
            },
            {
              'id': 42,
              'title': '我的信息',
              'parent_id': 0,
              'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E4%BF%A1%E6%81%AF.png',
              'sort': 0,
              'url': '',
              'status': 1,
              'create_time': '2021-12-20 17:20:01',
              'update_time': '2021-12-20 17:20:04',
              'children': [
                {
                  'id': 39,
                  'title': '注册信息',
                  'parent_id': 42,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E7%AD%91%E9%BE%99.png',
                  'sort': 0,
                  'url': '/registinfo',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 40,
                  'title': '修改密码',
                  'parent_id': 42,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/updatePwd',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 41,
                  'title': '地址管理',
                  'parent_id': 42,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/address',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                }
              ]
            },
            {
              'id': 49,
              'title': '我的兑换码',
              'parent_id': 0,
              'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E4%BF%A1%E6%81%AF.png',
              'sort': 0,
              'url': '',
              'status': 1,
              'create_time': '2021-12-20 18:37:06',
              'update_time': '2021-12-20 18:37:09',
              'children': [
                {
                  'id': 50,
                  'title': '未使用',
                  'parent_id': 49,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E7%AD%91%E9%BE%99.png',
                  'sort': 0,
                  'url': '/ExchangeCode',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 51,
                  'title': '已使用',
                  'parent_id': 49,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/usedExchangeCode',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 52,
                  'title': '已过期',
                  'parent_id': 49,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/expiredExchangeCode',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                }
              ]
            },
            {
              'id': 27,
              'title': '我的优惠券',
              'parent_id': 0,
              'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E4%BC%98%E6%83%A0%E5%88%B8.png',
              'sort': 0,
              'url': '',
              'status': 1,
              'create_time': '2021-12-20 17:20:01',
              'update_time': '2021-12-20 17:20:04',
              'children': [
                {
                  'id': 28,
                  'title': '未使用',
                  'parent_id': 27,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E7%AD%91%E9%BE%99.png',
                  'sort': 0,
                  'url': '/coupon',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 29,
                  'title': '已使用',
                  'parent_id': 27,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/usedcoupon',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                },
                {
                  'id': 30,
                  'title': '已过期',
                  'parent_id': 27,
                  'image': 'http://zhulong-oss.oss-cn-beijing.aliyuncs.com/profile%5C%E6%88%91%E7%9A%84%E5%BF%AB%E9%80%92.png',
                  'sort': 0,
                  'url': '/expired',
                  'status': 1,
                  'create_time': '2021-12-20 17:20:01',
                  'update_time': '2021-12-20 17:20:04',
                  'children': []
                }
              ]
            }
          ]
          this.menuList = zkbresult
        } else {
          this.menuList = result
        }
      }
    },
    getUserInfo() {
      getLoginUserInfo({}).then(res => {
        const result = res.result
        this.uid = result.uid
        this.avatar = result.avatar
        this.is_avatar = result.is_avatar
        this.hint_show = !result.is_avatar
        this.vip_level = result.vip_level
        this.username = result.username
        this.cacheUsername({ username: result.username })
        this.cacheUid({ uid: result.uid })
        this.cacheAvatar({ avatar: result.avatar })
        this.cacheVipLevel({ vip_level: result.vip_level })
      }).catch(err => {
        console.log(err)
      })
      const p = {
        type: '1'
      }
      getRow(p).then(res => {
        if (res.result && res.result.name) {
          // if (this.isZkb) {
          //   this.zlb_name = '注考币'
          // } else {
          this.zlb_name = res.result.name
          // }
        }
      })
    },
    showArchive() {
      this.$router.push({ path: '/archive', query: { u: this.uid }})
      // this.$router.push('myCenter')
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    changeActive($event) {
      $event.currentTarget.className = 'active'
    },
    removeActive($event) {
      $event.currentTarget.className = ''
    },
    // 修改头像
    handleAvatarSuccess(res, file) {
      this.show_box = false
      const param = { filename: res.url }
      updateIcon(param).then((res) => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('头像修改成功')
          location.reload()
          // const avaurl = res.result.result
          // this.avatar = avaurl
          // this.getUserInfo()
          // // this.$message.success('头像修改成功')
          // location.reload()
        } else {
          this.$message.error('头像修改失败')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.vue-image-crop-upload .vicp-wrap{
  width: 500px;
}
/deep/.vue-image-crop-upload .vicp-wrap .vicp-operate a{
  color: #ee2e2e;
}
/deep/.vicp-preview .vicp-preview-item span {
  left: 0px;
}
/deep/.vicp-crop-right {
  margin-top: 20px;
  margin-right: 20px;
}
/deep/.el-menu-item {
  text-align: left;
  padding-left: 50px !important;
}
.mine {
  width: 1200px;
  margin: 0 auto;
}
.mine .tac {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  // margin-top: 20px;
  padding-top: 20px;
  // overflow: hidden;
}
.user {
  width: 215px;
  min-height: 195px;
  text-align: center;
  padding-top: 30px;
}
.icon_upload {
  display: inline-block;
}
.head {
  width: 76px;
  height: 76px;
  display: inline-block;
  img {
    width: 76px;
    height: 100%;
    border-radius: 50%;
  }
}
.change_ava {
  background: rgba(0, 0, 0, 0.5);
  display: none;
  width: 76px;
  height: 76px;
  position: absolute;
  top: 30px;
  left: 70px;
  text-align: center;
  border-radius: 50%;
  line-height: 76px;
  font-size: 14px;
  line-height: 76px;
  color: #fff;
  text-align: center;
}
.head:hover .change_ava {
  display: block;
  cursor: pointer;
}
.u_info {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  span {
    max-width: 150px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    line-height: 30px;
    color: #333333;

    display: inline-block;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  img {
    width: 17px;
    height: 21px;
    margin-left: 8px;
    margin-top: -22px;
  }
}
.p_main {
  display: inline-block;
  width: 100px;
  height: 30px;
  margin-top: 10px;
  line-height: 28px;
  font-size: 16px;
  text-align: center;
  border: solid 1px #ee2e2e;
  border-radius: 30px;
  color: #ee2e2e;
  cursor: pointer;
}
.item {
  width: 215px;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
  text-align: left;
  font-size: 16px !important;
  padding-left: 0px !important;
}
.item:focus {
  background-color: #fff !important;
}
.ttext {
  margin-left: 22px;
  display: flex;
  align-items: center;
  div,p{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div {
    width: 25px;
    // img{
    //   display: block;
    // }
  }
  p {
    margin-left: 5px;
  }
}
// .item:first-child{
//   padding: 10px 0;
// }
// .item img {
//   margin-right: 10px;
// }
// .item:hover{
//   border-left: 4px solid #ee2d2e;
//     box-sizing: border-box;
// }
.active {
  color: #ee2e2e;
  border-left: 4px solid #ee2d2e;
}
.tac {
  overflow: hidden;
}
.right {
  //右侧宽度
  width: 965px;
  margin-left: 20px;
  padding-bottom: 9999px;
  margin-bottom: -9999px;
  float: left;
  background-color: #fff;
}
.left {
  padding-bottom: 9999px;
  margin-bottom: -9999px;
  // min-height: 1000px;
  background-color: #fff;
  float: left;
}
.m_l {
  margin-left: 15px;
}
.el-col-12 {
  width: 215px;
  padding-left: 0px;
}
.el-menu {
  border: none;
}
/deep/.el-submenu__icon-arrow {
  display: none;
}
/deep/.el-menu-item-group__title {
  padding: 0 !important;
}
.el-submenu .el-menu-item {
  border-bottom: 1px solid #f2f2f2;
}
/deep/.el-submenu__title {
  font-size: 16px !important;
  padding-left: 0px !important;
  border-bottom: 1px solid #f2f2f2 !important;
}
/deep/.el-submenu__title:hover {
  background-color: #fdeaea;
}
/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: none;
  background-color: #fdeaea;
}
/deep/ .el-submenu.is-active .el-submenu__title {
  border-bottom-color: #fff !important;
}
.el_row {
  margin-top: 60px;
}
.need_ava{
  height: 37px;
  line-height: 37px;
  background-color: rgba(245, 232, 232, 1);
  text-align: center;
  font-size: 12px;
  color:#EE2E2E;
  margin-top:10px;
  cursor: pointer;
  .el-icon-close{
    float: right;
    color:#C2C2C2;
    margin-top:13px;
    margin-right: 5px;
    cursor: pointer;
  }
}
</style>
